import {motion} from "framer-motion"
import Header from "../../components/Header"
import HeaderImgae from "../../images/header-bg.jpg"
import team from "../../images/Team 1-min.jpg"
import Team from "../../images/Team 2-min.jpg"
import "./careers.css"

const Careers = () => {
  return (
<>
  <Header title="Join Our Team" image={HeaderImgae}>
  Build the Future with Us. Innovate, collaborate, Succeed
   </Header>
   <section className="about__story">
    <div className="container about__story-container">
<div className="gird__container">
  <div className="about__story-text">
<motion.h2
 initial={{ x: -100, opacity: 0 }}
 animate={{ x: 0, opacity: 1}}
 transition={{
   delay:0.3,
   x: {type: "spring", stiffness: 60},
   opacity: {duration: 1.2},
   ease: "easeIn",
   duration: 1,
 }}
>Current Opportunities</motion.h2>
<motion.p 
 initial={{ x: -100, opacity: 0 }}
 animate={{ x: 0, opacity: 1}}
 transition={{
   delay:0.3,
   x: {type: "spring", stiffness: 60},
   opacity: {duration: 1.2},
   ease: "easeIn",
   duration: 1,
 }}
className="write-up">We are always looking for talented individuals to join our team. Explore our current openings and find your place at agiphi</motion.p>
  </div>
  <div className="about__story-images">
    <motion.img 
     initial={{ y: -100, opacity: 0 }}
     animate={{ y: 0, opacity: 1}}
     transition={{
       delay:0.3,
       x: {type: "spring", stiffness: 60},
       opacity: {duration: 1},
       ease: "easeIn",
       duration: 1,
     }}
    src={Team} alt="working together" className="team__img"/>
    <motion.img 
     initial={{ y: 100, opacity: 0 }}
     animate={{ y: 0, opacity: 1}}
     transition={{
       delay:0.3,
       x: {type: "spring", stiffness: 60},
       opacity: {duration: 1.2},
       ease: "easeIn",
       duration: 1,
     }}
    src={team} alt="team-work" className="team__img"/>
  </div>
</div>
<div className="career__development">
  <div className="career__development-text">
<h2>Career Development</h2>
<p>We value continuous learning and development. Our team members have access to ongoing training and career advancement opportunities</p>
  </div>
</div>
</div>
</section>
</>
  )
}


export default Careers
