import {BrowserRouter, Routes, Route} from "react-router-dom"
import Careers from "./pages/careers/Careers";
import Home from "./pages/home/Home";
import Clients from "./pages/clients/Client";
import Blog from "./pages/blog/Blog";
import Product from "./pages/product/Product";
import Platform from "./pages/platform/Platform";
import Contact from "./pages/contact/Contact";
import NotFound from "./pages/notFound/notFound"
import Navbar from "./components/navbar/Navbar";
import ScrollToTop from "./ScrollToTop"
import { useState, useEffect } from "react";
import Footer from "./components/Footer";

function App() {
  const [theme, setTheme] = useState("dark")

  useEffect(()=> {
    document.body.className = theme;
  },[theme] );
  return (
    <div className={`app-container ${theme}`}>
    <BrowserRouter>
    <ScrollToTop />
    <Navbar theme={theme} setTheme={setTheme}/>
    <Routes>
      <Route path="/" element={ <Home/>}></Route>
      <Route path="/Careers" element={ <Careers/>}></Route>
      <Route path="/Product" element={<Product/>}></Route>
      <Route path="/Platform" element={ <Platform/>}></Route>
      <Route path="/Clients" element={<Clients/>}></Route>
      <Route path="/Blog" element={<Blog/>}></Route>
      <Route path="/Contact" element={<Contact/>}></Route>
      <Route path="*" element={<NotFound/>}></Route>
    </Routes> 
    <Footer />   
    </BrowserRouter>
    </div>
  );
}

export default App;
