import Header from '../../components/Header';
import HeaderImage from '../../images/header-bg.jpg'
import {Link} from 'react-router-dom'
import './platform.css'

const Platform = () => {
  return (
   <>
   <Header title="Data Annotation Platform" image={HeaderImage}>
   </Header>
    <div className="container platform">
          <section className="platform-development">
            <div className='text'>
            <h2>Innovative Data Annotation Platform</h2>
        <p>
          Use our platform to manage your data delivery and track progress in real time.
        </p>
            </div>
        <ul className="key-features">
          <li>
            <strong>Integration:</strong> Integrate your workflow from both local and cloud environments.
          </li>
          <li>
            <strong>Compatibility:</strong> Access the platform seamlessly from both mobile and desktop devices.
          </li>
        </ul>
      </section>

      <section className="visuals-demo">
        <h2>Screenshots</h2>
        <div className="screenshots">
          <img src="screenshot1.jpg" alt="Screenshot 1" />
          <img src="screenshot2.jpg" alt="Screenshot 2" />
          {/* Add more screenshots as needed */}
        </div>
        <Link to="/Contact" className="btn">Request Demo</Link>
      </section>
    </div>
    </>
  );
};

export default Platform;

