// import { useState } from "react"
import {Link} from "react-router-dom"
import {motion} from "framer-motion"
import Header from "../../components/Header"
import '../product/product.css'
import HeaderImgae from "../../images/header-bg.jpg"
import AnnotationOne from "../../images/anno1.jpg"
import AnnotationTwo from "../../images/anno2.png"
import ProductOne from "../../images/StockCake-Data.jpg"
import ProductTwo from "../../images/Tech Support Team.jpg"
// import {salesTools} from "../../data"

const Services = () => {
  return (
  <>
  <Header title="Our Products" image={HeaderImgae}>
  Explore our range of products designed to boost your data capabilities
  </Header>
   <section className="about__story">
    <div className="container about__story-container">
<div className="gird__container">
  <div className="about__story-text">
<motion.h2
 initial={{ x: -100, opacity: 0 }}
 animate={{ x: 0, opacity: 1}}
 transition={{
   delay:0.3,
   x: {type: "spring", stiffness: 60},
   opacity: {duration: 1},
   ease: "easeIn",
   duration: 1,
 }}
>Software Solutions</motion.h2>
<p>Discover our cutting-edge software solutions designed to enhance your data annotation and machine learning projects. Our software tools streamline your workflow and improve accuracy.</p>
<div className="visuals-product">
        <h2>Products</h2>
        <div className="product-decription">
<div className="flex-cont">
<img src="screenshot1.jpg" alt="Screenshot 1" />
<p>description</p>
</div>
<div className="flex-cont">
<img src="screenshot2.jpg" alt="Screenshot 2" />
<p>description</p>
</div>
<div className="flex-cont">
<img src="screenshot3.jpg" alt="Screenshot 3" />
<p>description</p>
</div>    
</div>
</div>

  </div>
  <div className="about__story-images">
    <motion.img 
     initial={{ y: -100, opacity: 0 }}
     animate={{ y: 0, opacity: 1}}
     transition={{
       delay:0.3,
       x: {type: "spring", stiffness: 60},
       opacity: {duration: 1},
       ease: "easeIn",
       duration: 1,
     }}
    src={AnnotationOne} alt="AI annotation" className="annot__top"/>
    <motion.img 
     initial={{ y: 100, opacity: 0 }}
     animate={{ y: 0, opacity: 1}}
     transition={{
       delay:0.3,
       x: {type: "spring", stiffness: 60},
       opacity: {duration: 1},
       ease: "easeIn",
       duration: 1,
     }}
    src={AnnotationTwo} alt="AI annotation" className="annot__below"/>
  </div>
</div>
<div className="gird__container-second">
<div className="about__story-images item-one">
    <motion.img 
     initial={{ y: -100, opacity: 0 }}
     animate={{ y: 0, opacity: 1}}
     transition={{
       delay:0.3,
       x: {type: "spring", stiffness: 60},
       opacity: {duration: 1},
       ease: "easeIn",
       duration: 1,
     }}
    src={ProductOne} alt="Data analyst" className="annot__top"/>
    <motion.img 
     initial={{ y: 100, opacity: 0 }}
     animate={{ y: 0, opacity: 1}}
     transition={{
       delay:0.3,
       x: {type: "spring", stiffness: 60},
       opacity: {duration: 1},
       ease: "easeIn",
       duration: 1,
     }}
    src={ProductTwo} alt="Customer care center" className="annot__below"/>
  </div>
  <div className="about__story-text second">
<motion.h2
 initial={{ x: -100, opacity: 0 }}
 animate={{ x: 0, opacity: 1}}
 transition={{
   delay:0.3,
   x: {type: "spring", stiffness: 60},
   opacity: {duration: 1},
   ease: "easeIn",
   duration: 1,
 }}
>Reliable Hardware for Optimal Performance</motion.h2>
<motion.p
 initial={{ x: -100, opacity: 0 }}
 animate={{ x: 0, opacity: 1}}
 transition={{
   delay:0.3,
   x: {type: "spring", stiffness: 60},
   opacity: {duration: 1},
   ease: "easeIn",
   duration: 1,
 }}
>Equip your team with our high-performance hardware designed to support your data processing and machine learning needs. Our hardware solutions are built for reliability and efficiency.</motion.p>
<div className="flex__cont">
<div className="product__offer">
<h3>Products Offered</h3>
        <ul>
          <li>GPUs</li>
          <li>CPUs</li>
          <li>High-Performance Workstations</li>
        </ul>
</div>
</div>
  </div>
</div>
<div className="third-section">
    <div className="top">
    <motion.h2
 initial={{ x: -100, opacity: 0 }}
 animate={{ x: 0, opacity: 1}}
 transition={{
   delay:0.3,
   x: {type: "spring", stiffness: 60},
   opacity: {duration: 1},
   ease: "easeIn",
   duration: 1,
 }}
>Show Your agiphi Spirit</motion.h2>
<motion.p
 initial={{ x: -100, opacity: 0 }}
 animate={{ x: 0, opacity: 1}}
 transition={{
   delay:0.3,
   x: {type: "spring", stiffness: 60},
   opacity: {duration: 1},
   ease: "easeIn",
   duration: 1,
 }}
>Wear your support for agiphi with our branded apparel. Our range includes comfortable and stylish options for all team members and supporters.</motion.p>
    </div>
<div className="product__line">
<h3>Products</h3>
        <ul>
          <li>Branded T-Shirts</li>
          <li>Hoodies</li>
          <li>Caps</li>
        </ul>
</div>
<div className="product__line">
<h3>Explore Our Store</h3>
<Link to="/" className="btn">Visit Our Store</Link>
</div>

  </div>
    </div>
   </section>
  </>
)
}

export default Services
