import {motion} from "framer-motion"
import aiProduct from "../images/AI product example.jpg"
import dataAnnot from "../images/data annotation.jpg"
import aiBrain from "../images/StockCake-AI Brain Concept_1718932424.jpg"
import SectionHead from "./SectionHead"
const ComapnyOverview = () => {
  return (
<section className="company-overview">
    <div className="container company__container">
        <SectionHead title="Company Overview" text="Leverage our expertise for all your multimodal training and data annotation needs. Partnering with leading ML Companies, we offer a comprehensive suite of tools and services to enhance your ML scaling capabilities."/>
        <div 
        
        className="company-overview-img">
            <motion.img 
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1}}
            transition={{
              delay:0.2,
              x: {type: "spring", stiffness: 60},
              opacity: {duration: 0.2},
              ease: "easeIn",
              duration: 1,
            }}
            src={aiProduct} alt="" />
            <motion.img 
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1}}
            transition={{
              delay:0.2,
              x: {type: "spring", stiffness: 60},
              opacity: {duration: 0.4},
              ease: "easeIn",
              duration: 1,
            }}
            src={dataAnnot} alt="" />
            <motion.img 
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1}}
            transition={{
              delay:0.2,
              x: {type: "spring", stiffness: 60},
              opacity: {duration: 0.2},
              ease: "easeIn",
              duration: 1,
            }}
            src={aiBrain} alt="" />
        </div>
    </div>
</section>
  )
}

export default ComapnyOverview
