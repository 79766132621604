import { Link } from "react-router-dom"
import Header from "../../components/Header"
import HeaderImgae from "../../images/header-bg.jpg"
import ArticleImage1 from "../../images/ai-generated-8540914_1920-min.jpg"
import ArticleImage2 from "../../images/engineer-4690505_1920-min.jpg"
import ArticleImage3 from "../../images/StockCake-AI Brain Concept_1718932424.jpg"
import "../blog/blog.css"

const blog = () => {
  return (
<>
  <Header title="Insights and Updates" image={HeaderImgae}>
  Constantly updating and providing insights on great innovations and development on varieties of topics and our products as well
   </Header>
   <section className="blog__container">
    <div className="container">
      <div className="blog__text">
      <h2>Recent Posts</h2>
      <p>Stay updated with our latest insights on AI training, data annotation, and industry trends</p>
      </div>
    <div className="blog onboarding-steps">
          <div className="blog__card card">
          <img src={ArticleImage1} alt="The Future of Data Annotation" />
            <div className="blog__content">
                <p>The Ultimate Future and Experience of AI Data Annotation</p>
                <Link to="/article3" className="btn">Read More</Link>
              </div>
          </div>
          <div className="blog__card card">
          <img src={ArticleImage2} alt="How AI Chatbots are Transforming Customer Service" />
          <div className="blog__content">
                <p>How AI Chatbots are Transforming Customer Service</p>
                <Link to="/article3" className="btn">Read More</Link>
              </div>
          </div>
          <div className="blog__card card">
          <img src={ArticleImage3} alt="Leveraging Open Source Tools for AI Training" />
       <div className="blog__content">
                <p>Leveraging Open Source Tools for AI Training</p>
                <Link to="/article3" className="btn">Read More</Link>
              </div>
          </div>
        </div>
        <div className="subscription-section">
            <h2>Subscribe to Our Blog</h2>
            <p>Never miss an update. Subscribe to receive the latest articles and insights.</p>
            <form className="subscription-form">
              <input type="email" placeholder="Email address" required />
              <button type="submit" className="btn">Subscribe</button>
            </form>
          </div>
    </div>
</section>
</>
  )
}

export default blog