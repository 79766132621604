import MainHeader from '../../components/MainHeader'
import CompanyOverview from '../../components/ComapnyOverview'
import  '../home/home.css'
import OurServices from '../../components/OurServices'

const Home = () => {
  return (
  <>
    <MainHeader/>
    <CompanyOverview/>
    <OurServices/>
  </>
)
}

export default Home