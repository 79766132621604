import {FaRobot, FaShoppingCart, FaCode} from "react-icons/fa"
import { IoCallOutline, IoLocateOutline } from "react-icons/io5";
import { MdOutlineAlternateEmail } from "react-icons/md";
import img1 from "../src/images/IBMlogo-min.jpg"
import img2 from "../src/images/amplitudeLogo-min.jpg"
import img3 from "../src/images/armLogo-min.jpg"
import img4 from "../src/images/canvaLogo-min.jpg"
import img5 from "../src/images/dellLogo-min.jpg"
import img6 from "../src/images/greenLogo-min.jpg"
import img7 from "../src/images/hubSpotLogo-min.jpg"
import img8 from "../src/images/larkLogo-min.jpg"
import img9 from "../src/images/newrelicLogo-min.jpg"
import img10 from "../src/images/nestuLogo-min.jpg"
import img12 from "../src/images/oracleLogo-min.jpg"
import img13 from "../src/images/stripeLogo-min.jpg"
import img14 from "../src/images/vantaLogo-min.jpg"
import img15 from "../src/images/wLogo-min.jpg"
import img16 from "../src/images/zendeskLogo-min.jpg"
import img17 from "../src/images/mongoDBLogo-min.jpg"
import img18 from "../src/images/letterNLogo-min.jpg"



export const services = [
    {
        id: 1,
        icon: <FaRobot />,
        title: "Data Annotation",
        info: "Leverage our expertise in precise data annotation to train robust models."
    },
    {
        id: 2,
        icon: <FaShoppingCart />,
        title: "Product Sales",
        info:  "Discover our range of software and hardware products."
    },
    {
        id: 3,
        icon: <FaCode />,
        title: "Custom Solutions",
        info:  "Get tailored solutions for optimal performance and scalability."
    },
]



  
  
  // export const salesTools = [
  //   'Monday.com',
  //   'Salesforce',
  //   'Other CRM Tools',
  // ];


  export const contacts = [
    {
        name: "Email",
        value: "info@agiphi.com",
        icon: <MdOutlineAlternateEmail />
    },
    {
        name: "Phone Number",
        value: "804 234 4599",
        icon: <IoCallOutline />
    },
    {
        name: "Address",
        value: "Silver District West, Ashburn, VA 20148",
        icon: <IoLocateOutline />
    }

  ]

//   export const testimonials = [
//     {
//     id: 1,
//     name: "Diana Ayi",
//     story: " After struggling with data inaccuracies for years, Diana Ayi partnered with us and saw a dramatic improvement. By using our annotation services, they improved data accuracy by 75%, which significantly boosted their operational efficiency and decision-making capabilities.",
//     avatar: require("./images/hair-Diana Ayi.jpg")
//     },
//     {
//         id: 2,
//         name: "Daniela Vinyo",
//         story: "Faced with lengthy processing times, Daniela Vinyo turned to our automation solutions. We helped them reduce processing time by 50%, allowing them to reallocate resources and focus on growth strategies, resulting in improved overall performance.",
//         avatar: require("./images/Daniela.jpg")
//         },  
//         {
//             id: 3,
//             name: "Edem Quist",
//             story: "Edem Quist needed to engage their users more effectively. Our personalized marketing strategies increased user engagement by 40%.This boost in engagement helped them foster stronger customer relationships and enhance their brand presence.",
//             avatar: require("./images/man-man-Edem.jpg")
//             },  
//             {
//                 id: 4,
//                 name: "Grace Page",
//                 story: "Looking to maximize their return on investment, Grace Page utilized our comprehensive data analysis services.They saw a 60% boost in ROI, enabling them to make more informed business decisions and achieve their financial goals more quickly.",
//                 avatar: require("./images/glasses-Grace Page.jpg")
//                 },    
//                 {
//                     id: 5,
//                     name: "Ben Lavoe",
//                     story: "Ben Lavoe wanted to enhance their customer service through automated solutions. By integrating our chatbot services,they increased sales by 30% and achieved faster response times, leading to higher customer satisfaction and loyalty.",
//                     avatar: require("./images/man-man-Ben Lavoe.jpg")
//                     },    
  
//   ]





 export const partnersLogo = [
  { src: img1, alt: "IBM Logo" },
  { src: img2, alt: "Amplitude Logo" },
  { src: img3, alt: "ARM Logo" },
  { src: img4, alt: "Canva Logo" },
  { src: img5, alt: "Dell Logo" },
  { src: img6, alt: "Green Logo" },
  { src: img7, alt: "HubSpot Logo" },
  { src: img8, alt: "Lark Logo" },
  { src: img9, alt: "newrelicLogo" },
  { src: img10, alt: "Nestul Logo" },
  { src: img12, alt: "oracle Logo" },
  { src: img13, alt: "stripe Logo" },
  { src: img14, alt: "vanta Logo" },
  { src: img15, alt: "wLogo Logo" },
  { src: img16, alt: "zendesk Logo" },
  { src: img17, alt: "mongoDB Logo" },
  { src: img18, alt: "letterN Logo" },

]
    
