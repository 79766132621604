import {motion} from "framer-motion"
const SectionHead = ({title, text, icon}) => {
  return (
        <motion.div 
        initial={{ x: 1, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1}}
        transition={{
          delay:0.3,
          x: {type: "spring", stiffness: 60},
          opacity: {duration: 1},
          ease: "easeIn",
          duration: 1,
        }}
        className="section__head">
        <span>{icon}</span>
        <h2 className="company__heading">{title}</h2>
        <p>{text}</p>
        </motion.div> 
  )
}

export default SectionHead