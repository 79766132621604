import {Link} from "react-router-dom"
import {motion} from "framer-motion"
import headerImage from ".././images/main__header-image.jpg"
import "../components/mainHeader.css"

const MainHeader = () => {
  return (
    <header className="container main__header">
      <div className="main__header-container">
        <div className="main__header-left">
          <motion.h1 
          initial={{ x: -1, opacity: 0 }}
          animate={{ x: 0, opacity: 1}}
          transition={{
            delay:0.3,
            x: {type: "spring", stiffness: 60},
            opacity: {duration: 1},
            ease: "easeIn",
            duration: 1,
          }}>Advanced ML Data Annotation <span>Multimodal Data Labeling for ML Enhancement</span></motion.h1>
          <motion.p
          initial={{ x: -1, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1}}
          viewport={{ once: true}}
          transition={{
            delay:0.3,
            x: {type: "spring", stiffness: 60},
            opacity: {duration: 1},
            ease: "easeIn",
            duration: 0.6,
          }}
          >Maximize your Data's potential with Us.</motion.p>
          <Link to="/Contact" className="btn">Get Started</Link>
        </div>
        <div className="main__header-right">
          <div className="main__header-image">
          <motion.img 
          initial={{ x: 1, opacity: 0}}
          animate={{ x: 0, opacity: 1}} 
          transition={{delay: 0.3,
x: {type: "spring", stiffness: 60},
opacity: {duration: 1},
ease:"easeIn",
duration: 1,
          }}
          src={headerImage} alt="AI Robotics" />
          </div>
        </div>
      </div>

    </header>
  )
}

export default MainHeader
