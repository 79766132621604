import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Header from "../../components/Header"
import  '../contact/contact.css'
import HeaderImage from '../../images/contact-bg.jpg'
import {contacts} from "../../data"

const Contact = () => {

// External
const form = useRef();
const [successMessage, setSuccessMessage] = useState(false);

const sendEmail = (e) => {
  e.preventDefault();

  emailjs
    .sendForm('service_xpek43m', 'template_0eua3xf', form.current, {
      publicKey: 'CyEcySrhRicEBDOBK',
    })
    .then(
      () => {
        console.log('SUCCESS!');
        setSuccessMessage(true);
          setTimeout(() => setSuccessMessage(false), 3000);
      },
      (error) => {
        console.log('FAILED...', error.text);
      },
    );

    e.target.reset()
};


  return (
   <>
   <Header title="Get In Touch" image={HeaderImage}>
   We’re here to help. Please fill out the form below, and we’ll get back to you shortly
   </Header>
   <section className="container contact__holder">
<form ref={form} onSubmit={sendEmail}>
  <h2>Contact Form</h2>
  {successMessage && <div className="success-message">Your message has been sent successfully!</div>}
  <div className="input-box">
    <label>Full Name</label>
    <input type="text" name="from_name" className='field' placeholder='Enter your name' required />
  </div>
  <div className="input-box">
    <label>Email Address</label>
    <input type="email" name="from_email" className='field' placeholder='Enter your email' required />
  </div>
  <div className="input-box">
    <label>Your Message</label>
   <textarea name='message' className='field-mess' placeholder='Enter your message' required></textarea>
  </div>
  <input type="submit" value="Send" className='btn'/>
</form>
<div className="contact__options">
{
  contacts.map((contact, index) =>(
    <div className="option" key={index}>
      <div className="icon__container">
        {contact.icon}
      </div>
      <h3 className='name'>{contact.name}</h3>
      <h4 className="text__muted">{contact.value}</h4>
      <a href="/" className='btn'>  Contact Us</a>
    </div>
  ))
}
</div>
   </section>
   </>
  )
}

export default Contact
