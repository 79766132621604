import SectionHead from "./SectionHead"
import {services} from "../data"
import Card from "./Card"

const OurServices = () => {
  return (
  <section className="our-services">
    <div className="container our-services-container">
        <SectionHead title="Our Core Services" text="Our commitment to quality and innovation ensures that you receive tailored solutions to meet your unique needs."/>
    <div
    className="services__wrapper">
{
  services.map(({id, icon, title, info})=>{
return(
  
  <Card className="services__services" key={id}>
 <div className="card-icon">
            <span>{icon}</span>
        </div>
        <div className="card-content">
            <h3>{title}</h3>
            <p>{info}</p>
        </div>
  </Card>
)
  }
  )
}
    </div>
    </div>
  </section>
  )
}

export default OurServices